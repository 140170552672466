body {
    background-color: #e9eff4;
    font-family: Arial, Helvetica;
}
/* .nws new news overrides */
.nws .container.article .headerMenu {
    padding-top: 0;
}

.nws .container.article .headerMenu .topPanel {
    border-top: 0;
    width: 100%;
    height: 30px;
    margin-top: -5px;
}
.nws .container.article .headerMenu .calendar,
.nws .container.article .headerMenu .nameDay,
.nws .container.article .headerMenu .linkMagazines a {
    font-weight: normal;
    color: #707b87;
    font-size: 15px;
    border: 0;
}
.nws .container.article .headerMenu .nameDay {
    max-width: 360px;
}
.nws .nws-notit {
    height: 5px;
    overflow: hidden;
}
.nws #breadcrumbs {
    background: transparent;
    border-bottom: 0px;
}
.nws .breadcrumbs {
    margin: 11px 0 10px 0;
}
.nws .container .grid_12 .articleContainer.littleArticle .wrapper {
    margin: 10px 0px;
}
.nws .nws-art.articleContainer.bigArticle h3.title {
    padding-top: 0px;
}
.nws .nws-art.articleContainer.bigArticle h3.title a {
    font-size: 36px;
    padding-right: 20px;
    padding-top: 10px;
    display: block;
    line-height: 42px;
}
.nws .nws-art.articleContainer.bigArticle .articlePhoto {
    position: relative;
    margin: 10px 0px 20px 0;
}
.nws .article .headerMenu .calendar {
    font-weight: bold;
}
.nws .article .headerMenu .calendar span {
    display: inline-block;
    width: 15px;
    text-align: right;
}
.nws .article .headerMenu .linkMagazines {
    float: right;
}
.nws .article .headerMenu .searchIco {
    float: right;
    width: 40px;
    height: 30px;
    margin-left: 5px;
    background: transparent url("https://img2.cncenter.cz/images/blesk/news/icons.png?v=1") 10px 5px no-repeat;
    position: relative;
    margin-left: 5px;
    cursor: pointer;
    transition: all 0.25s;
}
.nws .nws-noimg .wrapper {
    padding-left: 0px;
    padding-right: 20px;
    height: auto;
    padding-bottom: 30px;
}
.nws .container .grid_12 .wrapper {
    margin: 0 10px;
}
.nws .nws-art.articleContainer.middleArticle.nws-noimg {
    height: auto;
}
.nws .headerMenu .searchIco.searchAct {
    background: transparent url("https://img2.cncenter.cz/images/blesk/news/icons.png?v=1") 10px -25px no-repeat;
}
.nws .searchIco .searchBox {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    top: 41px;
    overflow: hidden;
    width: 0;
    height: 70px;
    z-index: 0;
    right: 0;
    width: auto;
    min-width: 400px;
    height: 38px;
}
.nws .searchIco.searchAct .searchBox {
    visibility: visible;
    opacity: 1;
    white-space: nowrap;
    text-align: right;
    z-index: 100;
    padding: 0px 0 10px 10px;
    display: inline-block;
    top: -7px;
}
.nws .searchIco #searchIco {
    width: 40px;
    height: 30px;
    float: right;
}
.nws .searchBox form:after {
    display: none;
}
.nws .searchIco.searchAct .searchBox form {
    float: right;
}
.nws .searchIco.searchAct .searchBox form input {
}
.nws .searchBox .searchField {
    float: right;
    width: 290px;
    border: 1px solid #cad5df;
    border-right: 0;
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
}
.nws .searchBox form .colorBtn {
    float: right;
    background-color: #4b8afb;
    font-family: Arial, Helvetica;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: normal;
    height: 40px;
}
.nws .logoBlesk,
.nws .article .logoBlesk {
    width: 156px;
    height: 69px;
    background-image: url("https://img2.cncenter.cz/images/blesk/news/mainlogo_bleskzpravy.png?v2");
    position: absolute;
}
.nws .logoBlesk a {
    display: inline-block;
    width: 49%;
    height: 68px;
    background: none;
}
.nws .logoBlesk a:first-of-type {
    width: 48%;
    height: 30%;
    z-index: 100;
    left: 6px;
}
.nws .logoBlesk a:nth-child(2) {
    width: 100%;
    height: 82%;
    float: left;
    left: 0px;
    top: 12px;
    position: absolute;
}
.nws .article #menu {
    margin-left: 160px;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
}
.nws .headerMenu > .wrapper {
    margin-top: 10px;
}
.nws #menu nav > ul > li {
    height: 68px;
    display: block;
    float: left;
    padding: 0;
    z-index: 5;
    background-color: #e9eff4;
}
.nws #menu nav > ul > li:first-of-type {
    margin-left: 0px;
}
.nws #menu nav > ul > li > a {
    display: block;
    float: left;
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 17px;
    padding: 0 23px;
    margin: 24px 0;
    height: 20px;
    line-height: 25px;
    color: #707b87;
    border-right: 1px solid #cad5df;
    overflow: hidden;
}
.nws #menu nav > ul > li:last-of-type > a {
    border-right: 0;
}
.nws #menu nav > ul > li > a:hover {
    color: #ff0000;
}
.nws #menu > li {
    position: relative;
}
.nws #menu .dropdown {
    padding: 5px 0 0 0;
    top: 67px;
    right: -30px;
    position: absolute;
    width: 150px;
    height: auto;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    -webkit-box-shadow: 3px 3px 5px -3px #98a3af;
    box-shadow: 3px 3px 5px -3px #98a3af;
}
.nws #menu .dropdown > li {
    display: block;
    background-color: rgba(112, 123, 135, 0.9);
    padding: 10px 20px;
    border-bottom: 1px solid #cad5df;
}
.nws #menu .dropdown > li:first-of-type {
    border-top: 1px solid #cad5df;
}
.nws #menu .dropdown > li:last-of-type {
    border-bottom: 0;
}
.nws #menu .dropdown > li > a {
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 16px;
    display: block;
    line-height: 20px;
    height: 18px;
    color: #e9eff4;
}
.nws #menu .dropdown > li.small-menu > a {
    font-size: 14px;
}
.nws #menu nav > ul > li:hover > .dropdown,
.nws #menu nav > ul > li > .dropdown:hover {
    visibility: visible;
    opacity: 1;
    right: 0;
}
.nws #menu .dropdown li:hover,
.nws #menu .dropdown li:hover a {
    color: white;
    background-color: rgba(112, 123, 135, 1);
}
.nws #menu .dropdown li.small-menu,
.nws #menu .dropdown li a.small-menu {
    font-size: 14px;
}
/*recentarticles-news-topsmall.xsl-malé topčlánku u prvního velkého TOP článku na HP*/
.smallnwsTOP {
    position: relative;
    width: 630px;
    display: block;
    float: left;
}
.smallnwsTOP .outer_8:last-of-type {
    margin-bottom: 15px;
}
.nws-boxT {
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 17px;
    color: #ff0000;
    margin-top: 10px;
    margin-bottom: 12px;
}
.nws .nws-art.articleContainer {
    background: transparent;
    padding: 0;
    border-left: 0;
    border-top: 0;
}
.nws .nws-art.articleContainer.bigArticle {
    margin-top: 40px;
    height: auto;
    margin-bottom: 40px;
}
.nws .nbtm:first-of-type > .nws-art.articleContainer.bigArticle {
    margin-top: 0;
    margin-bottom: 15px;
}
/*recentarticles-news-topclassic-od2articlu.xsl*/
.nws-b .outer_8.nbtm.from2article:first-of-type > .nws-art.articleContainer.bigArticle {
    margin-bottom: 40px;
}
.nws-b .outer_8.nbtm.from2article > .nws-art.articleContainer.bigArticle {
    margin-top: 40px !important;
}
.nws .nws-art.articleContainer.bigArticle .articlePhoto img {
    left: 0;
    top: 0;
    height: 317px;
    position: relative;
}
.nws-perex {
    margin-top: 5px;
    line-height: 1.2em;
    font-size: 15px;
    max-width: 90%;
}
.nws .color,
.nws a.color,
.nws a.hoverColor:hover,
.nws .nws-art.articleContainer:hover a.hoverColor {
    color: #ff0000 !important;
}
.nws .nb,
.nws .nbt,
.nws .nbtm {
    margin-bottom: 0px;
    padding-top: 0px;
    border-right: 1px solid #cad5df;
}
.nws .nbtm,
.nws .btm {
    border-bottom: 1px solid #cad5df;
}
.nws .nbtm:last-of-type,
.nws .nbtm .btm:last-of-type {
    border-bottom: 0px;
}
.nws .nws-art.articleContainer p.perex:before {
    display: none;
}
.nws .nws-art.articleContainer.middleArticle {
    height: 210px;
}
.nws .btm .nws-art.articleContainer.middleArticle {
    height: 200px;
}
.nws .nws-art.articleContainer.middleArticle .icon {
    left: 280px;
    top: 70px;
}
.nws .nws-art.articleContainer.middleArticle .wrapper .articleContext {
    margin-top: 15px;
}
.nws .nws-art.articleContainer.middleArticle h3.title,
.nws .article-body .linkArticle h3 {
    font-size: 24px;
    width: 90%;
    min-height: 110px;
    padding-top: 41px;
}
.nws .nws-art.articleContainer.middleArticle h3.title a {
    line-height: 32px;
}
.nws .nws-art.articleContainer.middleArticle p.perex {
    position: static;
    margin-top: 10px;
    margin-right: 20px;
    width: auto;
    height: auto;
}
.nws .nws-art.articleContainer.middleArticle .categoryTitle {
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    text-transform: uppercase;
    color: #ff0000;
    line-height: 26px;
    word-spacing: 3px;
    padding: 0px;
}
.nws .nws-art.articleContainer .categoryTitle {
    margin-top: 5px;
    padding: 0px !important;
}
.nws-title {
    position: relative;
    padding-left: 10px;
    padding-bottom: 10px;
}
.recent_news_cat_paid_small .nws-title-paddingTop {
    padding-top: 20px;
}
.nws-title a {
    color: black;
    font-size: 14px;
    line-height: 1.1em;
}
.nws-title a:before {
    content: "\2022";
    position: absolute;
    left: 0;
    color: #ff0000;
    line-height: 14px;
}
a.nws-more {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 30px;
    color: #4b8afb !important;
    font-weight: normal;
    font-size: 14px;
}
.nws-ts {
    color: #98a3af;
    font-size: 10px;
    clear: both;
    padding-left: 10px;
    margin-bottom: 3px;
}
.nws-list .nws-title {
    padding-bottom: 25px;
}
.nws-mact {
    color: #707b87;
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 17px;
    padding-left: 10px;
    padding-bottom: 25px;
    display: inline-block;
}
.nws .pagination {
    margin-top: 25px;
}
.nws .pagination .pageBefore,
.nws .pagination .pageAfter,
.nws .pagination li .nws .pagination a.pageBefore,
.nws .pagination a.pageAfter,
.nws .pagination li a {
    font-weight: normal;
    font-size: 15px;
}
.nws .pagination .pageAfter {
    margin-right: 25px;
}
.nws .pagination li a {
    font-weight: bold;
}
.nws .pagination li.aktpage a {
    color: #ff0000;
}
.nws .pagination .disabled {
    color: #cad5df;
}
.nws .pagination .pageBefore {
    float: left;
}
.nws .pagination .pageAfter {
    float: right;
}
.nws .pagination ul,
.nws .pagination li {
    display: inline-block;
}
.nws .nws-art.articleContainer.bigArticle .icon.iconPlay,
.nws .nws-art.articleContainer.bigArticle .icon.iconPhoto {
    top: 298px;
}
.naroky {
    position: relative;
}
.naroky .title {
    font-family: "tablet_gothic_condensed";
    font-size: 24px;
    line-height: 1.2em;
    color: #16212d;
    padding: 10px 55px 0 0;
}
.naroky .subtitle,
.naroky label {
    font-size: 11px;
    position: relative;

    display: inline-block;
    padding-right: 13px;
    margin-bottom: 5px;
}
.naroky label {
    width: 50%;
    float: left;
    display: inline-block;
    padding: 5px 0 0 0;
    margin-bottom: 15px;
}
.outer_4.naroky.mt10px {
    margin-top: 25px !important;
}
.outer_4.naroky.mt10px .articleContainer .logo-ombudsman {
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -845px -1550px;
    width: 69px;
    height: 59px;
    right: 7px;
    top: -27px;
}
.naroky a {
    text-decoration: underline;
    color: #16212d;
}
.naroky input[type="text"],
.naroky input[type="email"],
.naroky textarea {
    background-color: #e9eff4;
    border: 1px solid #d8e0e8;
    margin: 5px 0;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.5em;
    padding: 5px 10px;
}
.naroky #captchaImg {
    /* width: auto; */
    border: 1px solid #d8e0e8;
    margin: 5px 0;
    width: auto;
    height: 30px;
    box-sizing: border-box;
    line-height: 1.5em;
    padding: 0px;
    float: left;
}
.naroky input#captcha {
    float: right;
    width: 48%;
}
.naroky input#email {
    width: 60%;
    /* margin-right: 10px; */
}
.naroky input#phone {
    width: 35%;
    float: right;
}
.naroky input#conditions {
    float: left;
    width: 20px;
    margin: 4px 0px;
}
.naroky input#submit {
    width: 80px;
    float: right;
    font-family: "tablet_gothic_condensed";
    font-weight: bold;
    font-size: 16px;
}
.naroky input,
.naroky textarea {
    font-family: Arial, Helvetica;
    font-size: 12px;
}
.naroky textarea {
    margin-bottom: 5px;
    height: 120px;
}
.naroky .arrow {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    position: absolute;
    right: 0;
    top: 0px;
}
.naroky .colorBtn {
    border: 0px;
}
.naroky .thanks_overlay {
    display: none;
    position: absolute;
    top: 0px;
    background: rgba(220, 220, 220, 0.9);
    left: 0px;
    padding: 125px 15px 0 15px;
    height: 100%;
    text-align: center;
    font-family: "tablet_gothic_condensed";
    font-size: 18px;
    box-sizing: border-box;
    line-height: 1.5em;
}
.naroky .thanks_overlay::after {
    content: "+";
    position: absolute;
    font-family: Arial;
    top: 10px;
    right: 15px;
    border: 2px solid black;
    display: block;
    width: 24px;
    height: 24px;
    line-height: 25px;
    border-radius: 16px;
    font-size: 31px;
    text-align: center;
    overflow: hidden;
    transform: rotate(45deg);
    cursor: pointer;
}
.nws24h {
}
.nws24h > .outer_4 {
    background: white;
}
.nws24h .nws-boxT {
    margin-bottom: 0;
    border-bottom: 1px solid #e4eaef;
}
.nws24h .nws-boxT .logo24h {
    width: 38px;
    height: 38px;
    display: block;
    background: transparent url("https://img2.cncenter.cz/images/blesk/24hodin/logo-24hodin.jpg") 0 0 no-repeat;
    background-size: contain;
    float: left;
}
.nws24h .nws-lnkT {
    float: right;
    line-height: 40px;
    height: 38px;
    margin-right: 10px;
    overflow: hidden;
    font-family: Arial, Helvetica;
    text-transform: none;
}
.nws24h .nws-24hf {
    height: 38px;
    border-top: 1px solid #e4eaef;
}
.nws24h .nws-mact {
    height: 38px;
    overflow: hidden;
    font-family: arial;
    text-transform: none;
    color: #ff0000;
    margin: 0 10px;
    padding: 0;
    display: block;
    line-height: 40px;
}
.nws24h .nws-mact:after {
    content: " ";
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -248px -216px transparent !important;
    display: block;
    width: 10px;
    height: 10px;
    float: right;
    margin-top: 14px;
}
.nws24h .nws-ts {
    display: block;
    font-size: 16px;
    color: #16212d;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
}
.nws24h .nws-list .nws-title {
    padding-bottom: 0px;
    font-size: 14px;
    font-weight: normal;
    padding-right: 10px;
}
.nws24h .nws-list:after {
    content: " ";
    display: block;
    margin: 0px 10px;
    border-top: 1px solid #e4eaef;
    height: 1px;
    margin-top: 20px;
}
.nws24h .nws-list.last:after {
    border-top: 0px;
}
.nws24h .nws-title a:before {
    content: none;
    display: none;
}
.nws24h .nws-list .articlePhoto {
    display: block;
    margin: 15px 10px 0 10px;
}
.nws24h .nws-list .articlePhoto img {
    width: 100%;
    height: auto;
    max-height: 200px;
}
.cont-24h {
    position: relative;
    display: block;
    float: left;
    background-color: white;
    margin-bottom: 20px;
    margin-top: 10px;
}
.cont-24h .articlePhoto {
    display: block;
    margin: 0;
    padding: 0;
    margin: 0 0 25px 0;
    position: relative;
    left: 0px;
    top: 0px;
}
.cont-24h .articlePhoto span.iconPlay,
.cont-24h .articlePhoto span.iconCamera {
    top: 160px;
    left: 4%;
    right: 4%;
    margin: auto;
    position: absolute;
}
.cont-24h .header24 {
    border-bottom: solid 1px #cad5df;
    position: relative;
    float: left;
}
.cont-24h .header24 .outer_4.nws-boxT {
    color: #ff0000;
    font-family: "Arial";
    text-transform: none;
    margin: 11px;
    font-weight: bold;
}
.cont-24h .header24 .logo_24h {
    position: absolute;
    right: 0px;
    width: 40px;
    height: 40px;
    background-image: url("https://img2.cncenter.cz/images/blesk/news/logo24_40x40.png");
}
.cont-24h .outer_4.nws-list {
    border-bottom: solid 1px #cad5df;
}
.cont-24h .outer_4.nws-list a.nws-ts {
    color: #000000;
    font-size: 16px;
    clear: both;
    padding-left: 0px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    display: block;
    cursor: pointer;
}
.cont-24h .outer_4.nws-list a.nws-ts:hover {
    color: #ff0000;
}
.cont-24h .outer_4.nws-list h3.nws24-title span {
    margin-bottom: 22px;
    display: block;
    color: #000000;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2em;
}
.cont-24h .outer_4.more-news-butt {
    margin: 10px;
}
.cont-24h .outer_4.more-news-butt a.nws-more-news {
    color: #ff0000;
    font-family: "Arial";
    text-transform: none;
    font-weight: bold;
    position: relative;
    width: 295px;
    display: block;
}
.cont-24h .outer_4.more-news-butt a.nws-more-news:before,
.cont-24h .outer_4.more-news-butt a.nws-more-news:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.cont-24h .outer_4.more-news-butt a.nws-more-news:before {
    border-color: rgba(0, 0, 0, 0);
    border-width: 11px;
    margin-top: -11px;
}
.cont-24h .outer_4.more-news-butt a.nws-more-news:after {
    border-color: rgba(255, 0, 0, 0);
    border-left-color: #ff0000;
    border-width: 5px;
    margin-top: -5px;
}
.cont-24h iframe {
    display: none;
}
